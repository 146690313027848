import React from 'react';
import Header from './Header';
import { IdleTimeoutPopup } from './IdleTimeoutPopup';
import { Alert } from './Alert';
import { CompanyContext } from '../CompanyContext';

export function Layout({ children }) {
    const { config, setError, t } = React.useContext(CompanyContext);

    React.useEffect(() => {
        if (config?.DisableOpp) {
            setError({
                Message: config.DisableOppMessage || t('This site is currently unavailable at this moment, please try again later.'),
                Dismissable: false
            });
        }
    }, [config]);

    return (
        <div>
            {config &&
                <IdleTimeoutPopup
                    homeUrl={config.HomeUrl}
                disabled={config.DisableOpp || window.location.pathname.indexOf('checkout') > -1}
                    idlingInSeconds={config.IdlingInSeconds}
                    timeoutInSeconds={config.TimeoutInSeconds} />
            }
            <Header />
            <Alert />
            {config && children}
        </div>
    );
}