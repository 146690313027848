import React from 'react';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';

import { CompanyContext } from '../CompanyContext';

export function Error() {
    const { company, t } = React.useContext(CompanyContext);

    return (
        company &&
        <div className="container text-center">
            <h1>{t('Error During Checkout')}</h1>
            <hr />
            <div className="text-center">
                <p className="lead text-danger payment-declined-text">
                    {t('Your transaction was cancelled due to an error during Checkout. Please ')} <Link to="/">click here to go back.</Link>
                </p>
                <p><Trans t={t}>Please refer to <Link to={'/' + company.Key + '/faqs'}>our FAQ</Link> for more information</Trans></p>
                <p><Trans t={t}>If you would like to try again, please <Link to="/">click here to return to the beginning of the form</Link></Trans></p>
            </div>
        </div>
    );
}
