import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Color from 'color';
import { CompanyContext } from '../CompanyContext';
import "./Header.css";

export default function Header({ title }) {
    const { config, company, t } = React.useContext(CompanyContext);
    const [styles, setStyles] = React.useState('');

    useEffect(() => {
        let color = Color(company ? company.PrimaryColor : '#314761');
        let borderColor = color.darken(.15);
        setStyles(`
            .header { border-bottom-color: ${color}; }
            .app-name { color: ${color.desaturate(.9).lighten(.25)}; }
            .btn-primary { background-color: ${color}; border-color: ${borderColor} }
            .btn-primary:hover { background-color: ${color.darken(.15)}; border-color: ${color.darken(.15)} }
            .btn-primary.disabled, .btn-primary:disabled { background-color: ${color.lighten(.3)}; border-color: ${borderColor.lighten(.5)} }
            .form-control:focus { border-color: ${color.lighten(.25)}; box-shadow: 0 0 0 0.2rem ${color.fade(.8)}; }
            .version { font-size: xx-small; }
        `);
    }, [company]);

    return (
        <>
            <style type="text/css">
                {styles}
            </style>
            <header className="header">
                <nav className="navbar container">
                    {company ?
                        <Link className="navbar-brand" to="/">
                            <img src={company.Logo} className="organization-logo d-inline-block align-top" alt={t(company.FullName)} title={t(company.FullName)} hidden={company === null} />
                            <div className="brand-name">
                                <div className="organization-name">{t(company.FullName)}</div>
                                <div className="app-name">{t(title) || t('Online Payments Portal')}</div>
                                <div className="version">{t('V ')}{!!config && config.Version}</div>
                            </div>
                        </Link> :
                        <Link className="navbar-brand brand-none" to="/">
                            <div className="brand-name text-center">
                                <div className="app-name">{t(title) || t('Online Payments Portal')}</div>
                                <div className="version">{t('V ')}{!!config && config.Version}</div>
                            </div>
                        </Link>
                    }
                </nav>
            </header>
        </>
    );
}
