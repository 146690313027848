import React, { Component } from 'react';
import "./Button.css";

export class Button extends Component {
    static displayName = Button.name;
    static defaultProps = {
        className: '',
        disabled: false,
        type: 'button',
        loading: false
    }
       
  render() {
      return (
          this.props.hidden ?
              null :
              <button
                  type={this.props.type}
                  id={ this.props.id}
                  className={'btn ' + this.props.className}
                  disabled={this.props.loading || this.props.disabled}
                  onClick={this.props.onClick}>
                  <span className={'spinner-border spinner-border-sm' + (this.props.loading ? '' : ' d-none')} role="status" aria-hidden="true"></span>
                  {this.props.children}
              </button>
    );
  }
}
