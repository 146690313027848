import React, { Component } from 'react';
import "./Modal.css";

export class Modal extends Component {
    static displayName = Modal.name;

    render() {
        return (!this.props.hidden &&            
            <div className="modal-open">
                <div className="backdrop" onClick={this.props.onClose}></div>
                <div className="modal fade show">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body large">
                                {this.props.children}
                            </div>
                            {this.props.showFooter &&
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={this.props.onButtonClick}>{this.props.buttonLabel}</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
