import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import format from 'format-duration';
import useInterval from 'use-interval'

import { Modal } from './Modal';
import { CompanyContext } from '../CompanyContext';

export function IdleTimeoutPopup({ disabled, homeUrl, idlingInSeconds, timeoutInSeconds }) {
    const { t } = React.useContext(CompanyContext);
    const [isIdling, setIsIdling] = React.useState(false);
    const [isTimeout, setIsTimeout] = React.useState(false);
    const [idlingRemaining, setIdlingRemaining] = React.useState(0);
    const [timeoutRemaining, setTimeoutRemaining] = React.useState(0);
    let timeoutTimer = React.useRef();

    const {
        reset,
        pause,
        getRemainingTime
    } = useIdleTimer({
        timeout: idlingInSeconds * 1000,
        onIdle: e => { onIdle(e); }
    })

    useEffect(() => {
        setIdlingRemaining(getRemainingTime());
    }, [idlingInSeconds]);

    useInterval(() => {
            setIdlingRemaining(getRemainingTime());
            setTimeoutRemaining(timeoutRemaining - (isIdling && isTimeout === false ? 1000 : 0));
    }, 1000);

    var onIdle = (e) => {
        let isHomepage = window.location.pathname === '/'; // quick hack to check if we are on homepage
        let enableTimeout = disabled === false && isHomepage === false;

        setIsIdling(enableTimeout);
        setTimeoutRemaining(timeoutInSeconds * 1000);

        if (enableTimeout) {
            pause();
            timeoutTimer.current = setTimeout(function () {
                setIsTimeout(true);
                setTimeout(function () {
                    window.location = homeUrl;
                }, 2000);
            }, timeoutInSeconds * 1000);
        }
    };

    var onTimerReset = () => {
        clearTimeout(timeoutTimer.current);
        reset();
        setIsIdling(false);
        setIsTimeout(false);
    }

    return (
        !disabled && isIdling === true &&
        <Modal
            buttonLabel={t('Keep My Session Active')}
            showFooter={!isTimeout}
            onButtonClick={onTimerReset}>
            {isTimeout ?
                t('Your session has timed out. Please wait while you are being redirected...') :
                t('Your session will timeout in ') + format(timeoutRemaining)}
        </Modal>
    );
}
