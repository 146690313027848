import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import NumberFormat from 'react-number-format';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { Trans } from 'react-i18next';
import { RealexHpp } from '../lib/rxp-js/dist/rxp-js';

import { Button } from "./Button.js";
import { useFetch } from "./useFetch";
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../CompanyContext';

import './Payment.css';

export function Payment() {
    const { company, config, t } = React.useContext(CompanyContext);
    const history = useHistory();
    let { org, section } = useParams();

    let [order, setOrder] = React.useState({});
    let [invoices, setInvoices] = React.useState([]);
    let [submitEnabled, setSubmitEnabled] = React.useState([false]);
    let [ hideCheckoutSection, setHideCheckoutSection ] = React.useState(true);
    
    let totalPaymentAmount = (invoices) => invoices.map(invoice => invoice.Payment).reduce((a, b) => a + b, 0);

    React.useEffect(() => {
        if (section !== 'review' && hideCheckoutSection === false) {
            setHideCheckoutSection(true);
        }
    }, [hideCheckoutSection, setHideCheckoutSection, section]);

    return (company &&
        <div className="container content-container">
            {!section &&
                <>
                    <h1>{t('Pay Hospital Bill')}</h1>
                    <p>{t('Step 1 of 4')}</p>
                    <hr />

                    <div className="card card-body bg-light border-light">
                        <p>{t('Please complete the following information as per your invoice.')}</p>
                        <div>
                            {company.InvoiceSampleUrl && <a href={company.InvoiceSampleUrl} target="_blank" className="d-block">{t('Hospital Bill/Invoice Sample')}</a>}
                            {company.StatementSampleUrl && <a href={company.StatementSampleUrl} target="_blank" className="d-block">{t('Hospital Statement Sample')}</a>}
                            <Link to={'/' + company.Key + '/faqs'} target="_blank">{t('Online Payment Frequently Asked Questions (FAQs)')}</Link>
                        </div>
                    </div>

                    <br />
                    <h6 className="large">{company.FullName}'s <a href={company.PrivacyUrl} target="_blank">{t('Privacy Policy')}</a>.</h6>
                    <hr />

                    <InvoiceValidiate setOrder={setOrder} />

                    {order?.Invoices &&
                        <>
                            <Element name="invoice-cart"><hr /></Element>

                            <h3>{t('Invoice Cart')}</h3>
                            <p>{t('Please note: All invoice amounts are in Canadian Dollars (CAD)')}</p>

                            <InvoiceCart orderInvoices={order.Invoices} setInvoices={setInvoices} submitEnabled={submitEnabled} setSubmitEnabled={setSubmitEnabled} />

                            <dl className="row">
                                <dt className="col-sm-4">{t('Total Invoice Amount')}</dt>
                                <dd className="col-sm-8 payment-amount"><Currency value={order.Invoices.map(i => i.InvoiceAmount).reduce((a, b) => a + b, 0)} /></dd>

                                <dt className="col-sm-4">{t('Total Payment')}</dt>
                                <dd className="col-sm-8 payment-amount text-success"><Currency value={totalPaymentAmount(invoices)} /></dd>
                            </dl>

                            <p className="disclaimer text-danger">
                                {t('Disclaimer: If you do not see your invoice, please allow up to 3 to 5 business days for processing time in order for your invoice to show.')}
                            </p>
                <p className="text-danger" hidden={org == `VCHEH`}>
                                {t('Minimum $5 payment required per invoice')}
                            </p>
                    <Link to={`/${company.Key}/review`}
                        className={`btn btn-primary btn-lg btn-action ${invoices?.length === 0 || totalPaymentAmount(invoices) < 0 || !submitEnabled ? 'disabled' : ''}`}>
                    {t('Submit')} </Link>
                        </>
                    }
                </>
            }
            {section === 'review' && invoices.length > 0 &&
                <>
                    <div hidden={!hideCheckoutSection}>
                        <h1>{t('Review Information')}</h1>
                        <p>{t('Step 2 of 4')}</p>
                        <hr />

                    <OrderReview order={order} invoiceNumbers={invoices.map(invoice => invoice.InvoiceNumber).join('; ')} total={totalPaymentAmount(invoices)} />

                        <div className="card card-body bg-light border-light">
                            <p>{t('You will now be redirected to the Global Payments Portal in order to complete your payment. Please review the information above, if correct click Confirm. Otherwise, please click Go Back to make any changes. You will receive an order confirmation number.')}</p>

                            <h6>{t('Credit Card Safety')}</h6>
                            <p>
                                <Trans t={t} i18nKey="CreditCardSafetyDisclaimer">
                                    We do not store your credit card information.
                                    We follow the <a href="https://www.pcisecuritystandards.org/" target="_blank">Payment Card Industry Standards Council Guidelines</a> regarding
                                    the handling of credit card information.
                                </Trans>
                            </p>
                        </div>

                        <br />

                        <OrderForm order={order} invoices={invoices} total={totalPaymentAmount(invoices)} hideCheckoutSection={hideCheckoutSection} setHideCheckoutSection={setHideCheckoutSection} />    
                        <Link className="btn btn-default btn-lg" to={'/' + org}>{t('Go Back')}</Link>
                    </div>
                <div className="container" hidden={hideCheckoutSection}>
                    <div className="col">
                        <iframe id="checkoutIFrame" />
                    </div>
                    <div className="col">
                        <Link id="transactionCancelBtn" className="btn btn-danger" to={'/' + org + '/cancel'}>{t('Cancel')}</Link>
                    </div>
                </div>
                </>
            }
        </div>
    );
}

export function Currency({ value }) {
    const { t } = React.useContext(CompanyContext);

    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={t('CAD$')} fixedDecimalScale="true" decimalScale="2" />
}

function InvoiceValidiate({ setOrder }) {
    const { config, company, t } = React.useContext(CompanyContext);
    let { doFetch, isLoading } = useFetch();

    let [error, setError] = React.useState('');
    let [errorCount, setErrorCount] = React.useState(0);
    let [accountNumber, setAccountNumber] = React.useState('');
    let [searchTerm, setSearchTerm] = React.useState('');
    let [invoiceNumber, setInvoiceNumber] = React.useState('');
    let recaptchaRef = React.useRef();

    let sanitize = (value, regex) => {
        if (regex) {
            const match = value.match(regex);
            if (!match) {
                value = '';
            } else if (match.length > 0) {
                value = match[0];
            }
        }
        return value;
    }

    let validateInvoice = () => {        
        let recaptcha = recaptchaRef.current;
        let captchaValue = recaptcha.getValue();
        recaptcha.reset();
        setError(null);
        setOrder({});

        doFetch({
            url: `/Payment/GetInvoices/${company.Key}`,
            params: {
                AccountNumber: accountNumber,
                SearchTerm: searchTerm,
                InvoiceNumber: invoiceNumber,
                CompanyName: company.Key,
                ValidationToken: captchaValue
            }
        })
            .then(json => {
                var data = json?.data;

                if (data?.status === 200 && data?.errors) {
                    setError(data.errors[Object.keys(data.errors)[0]][0]); // Get the value of the first error in errors object
                    setErrorCount(errorCount + 1);
                }
                else if (data) {
                    setError(null);
                    setErrorCount(0);
                    setOrder(data);
                }
            });
    };

    return (
        <>
            {error &&
                <div className="alert alert-warning">
                    {t(error)}
                <span hidden={errorCount < 3}>
                    &nbsp;<Trans t={t}>Please refer to the <Link to={'/' + company.Key + '/faqs'}>FAQ page</Link></Trans>
                    </span>
                </div>
            }

            <div className="row">
                <div className="col-md-4">
                    <h4>{t('Account Number')}</h4>
                    <input type="text"
                        className="form-control"
                        name="AccountNumber"
                        placeholder={t('999999999')}
                        required
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(sanitize(e.target.value, /([A-Za-z\-\d]+)/))} />
                    <p className="form-text">{t('You can find this information on your invoice')}</p>
                </div>

                <div className="col-md-4">
                    <h4>{t('SearchTerm.Label')}</h4>
                    <input type="text"
                        className="form-control"
                        name="SearchTerm"
                        placeholder={t('SearchTerm.Placeholder')}
                        required
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(sanitize(e.target.value, company.SearchTermField.Regex))} />
                    <p className="form-text">{t('SearchTerm.Description')}</p>
                </div>

                <div className="col-md-4">
                    <h4>{t('Invoice Number')}</h4>
                    <input type="text"
                        className="form-control"
                        name="InvoiceNumber"
                        placeholder={t('Z123456789012-1')}
                        required
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(sanitize(e.target.value, /([a-z\d/|-]+)/i))} />
                    <p className="form-text">{t('Please enter exact invoice number, including any symbols')}</p>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    {config.CaptchaSiteKey && <ReCAPTCHA sitekey={config.CaptchaSiteKey} ref={recaptchaRef} />}

                    <br />
                    <Button className="btn-lg btn-primary btn-action"
                        onClick={() => validateInvoice()}
                        loading={isLoading}
                        disabled={!accountNumber || invoiceNumber.length === 0 || searchTerm.length === 0}>
                    {t('Find Invoices')}</Button>
                </div>
            </div>
        </>
    );
}

function InvoiceCart({ orderInvoices, setInvoices, submitEnabled, setSubmitEnabled }) {
    const { config, company, t } = React.useContext(CompanyContext);

    let [cart, setCart] = React.useState([]);

    React.useEffect(() => {
        if (orderInvoices?.length > 0) {
            let toggleInitState = company.AllowPartialInvoice === false;
            let invoices = orderInvoices.map(invoice => {
                invoice.Enabled = toggleInitState;
                invoice.Payment = invoice.InvoiceAmount;
                return invoice;
            });

            setCart(invoices);

            scroller.scrollTo('invoice-cart', {
                duration: 400,
                delay: 0,
                smooth: "easeInOutQuart"
            });
        }
    }, [orderInvoices]);

    React.useEffect(() => {
        let invoices = [];
        submitEnabled = true;
        cart.forEach(invoice => {
            if (isValidInvoice(invoice) && invoice.Enabled) {
                invoices.push({ InvoiceNumber: invoice.InvoiceNumber, Payment: parseFloat(invoice.Payment) });
            } else if (!isValidInvoice(invoice) && invoice.Enabled) {
                submitEnabled = false;
            }
        });

        setSubmitEnabled(submitEnabled);
        setInvoices(invoices);
    }, [cart]);

    const isValidInvoice = (invoice) => {
        return !invoice.Enabled || (invoice.Payment >= Math.min(invoice.InvoiceAmount, config.MinimumPayment) && invoice.Payment <= invoice.InvoiceAmount);
    }

    const handleInvoiceChange = (e, idx) => {

        let name = e.target.name.split('[')[0];
        let newInvoices = cart.map((invoice, i) => {
            if (idx !== i) return invoice;
            let newInvoice = { ...invoice };
            switch (name) {
                case 'Payment':
                    newInvoice[name] = parseFloat(e.target.value);
                    break;
                case 'Enabled':
                    newInvoice[name] = e.target.checked;
                    break;
                default:
                    newInvoice[name] = e.target.value;
            }
            return newInvoice;
        });

        setCart(newInvoices);
    };

    const toggleAll = (event) => {
        var newInvoices = cart.map((invoice, i) => {
            var newInvoice = { ...invoice };
            newInvoice.Enabled = event.target.checked && invoice.InvoiceAmount >= config.MinimumPayment;
            return newInvoice;
        });

        setCart(newInvoices);
    };

    return (cart.length > 0 &&
            <div className="invoice-table container">
                <div className="row table-head">
                    <div className="col-7 col-sm-3">
                        <input type="checkbox"
                            onChange={toggleAll}
                            disabled={company.AllowPartialInvoice === false}
                    checked={cart.filter(invoice => invoice.InvoiceAmount >= config.MinimumPayment).length > 0 &&
                        cart.filter(invoice => invoice.InvoiceAmount >= config.MinimumPayment).every(invoice => invoice.Enabled)} />
                        {t('Invoice Number')}
                    </div>
                    <div className="d-none d-sm-block col-sm-3">
                        {t('Treatment Facility')}
                    </div>
                    <div className="col-5 col-sm-2">
                        {t('Invoice')}
                    </div>
                    <div className="d-none d-sm-block col-sm-4">
                        {t('Payment')}
                    </div>
                </div>

                {cart.map((invoice, idx) =>
                    <div className="row table-row" key={invoice.InvoiceAmount}>
                        <div className="col-7 col-sm-3 order-1 invoice-number">
                            <label>
                                <input type="checkbox"
                                    name={`Enabled[${idx}]`}
                                    disabled={company.AllowPartialInvoice === false || invoice.InvoiceAmount < config.MinimumPayment}
                                    checked={invoice.Enabled}
                                    onChange={(e) => handleInvoiceChange(e, idx)} />
                                {invoice.InvoiceNumber}
                            </label>
                        </div>
                        <div className="col-12 col-sm-3 order-5 invoice-location text-muted">
                            {invoice.TreatmentFacility}
                        </div>
                        <div className="col-5 col-sm-2 order-9 invoice-amount">
                            <Currency value={invoice.InvoiceAmount} />
                        </div>
                        <div className="col-12 col-sm-4 order-12 invoice-payment">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <span className="d-block d-sm-none mr-1">{t('Payment')}</span>$
                                    </div>
                                </div>

                                <NumberFormat
                                    name={`Payment[${idx}]`}
                                    displayType={'input'}
                                    fixedDecimalScale="true"
                                    decimalScale="2"
                                    allowNegative="false"
                                    type="text"                                    
                                    className={isValidInvoice(invoice) ? "form-control" : "is-invalid form-control"}
                                    value={invoice.Payment}
                                    onChange={(e) => handleInvoiceChange(e, idx)}
                                    disabled={!invoice.Enabled || invoice.InvoiceAmount <= config.MinimumPayment || !company.AllowPartialPayment} />

                                {!isValidInvoice(invoice) &&
                                    <div className="text-danger small invalid-feedback">
                                        <Trans t={t} i18nKey="InvalidPaymentAmount">Payment should be between <Currency value={config.MinimumPayment} /> to <Currency value={invoice.InvoiceAmount} /></Trans>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
    );
}

function OrderReview({ order, invoiceNumbers, total }) {
    const { company, t } = React.useContext(CompanyContext);



    return (
        <dl className="row">
            <dt className="col-xs-6 col-md-4">{t('Account Number')}</dt>
            <dd className="col-xs-6 col-md-8">{order.AccountNumber}</dd>

            <dt className="col-xs-6 col-md-4">{t('SearchTerm.Label')}</dt>
            <dd className="col-xs-6 col-md-8">{order.SearchTerm}</dd>

            <dt className="col-xs-6 col-md-4">{t('Invoice Numbers')}</dt>
            <dd className="col-xs-6 col-md-8">{invoiceNumbers}</dd>

            <dt className="col-xs-6 col-md-4">{t('Total Payment')}</dt>
            <dd className="col-xs-6 col-md-8 text-success"><Currency value={total} /></dd>
        </dl>
    );
}

function OrderForm({ order, invoices, total, hideCheckoutSection, setHideCheckoutSection }) {
    const { config, company } = React.useContext(CompanyContext);
    let { doFetch, isLoading } = useFetch();
    const { t } = useTranslation();
    let [error, setError] = React.useState('');
    let [errorCount, setErrorCount] = React.useState(0);
    let [orderNumber, setOrderNumber] = React.useState('');
    let formRef = React.useRef();

    React.useEffect(() => {
        doFetch({
            url: `/Payment/Create/${company.Key}`,
            method: 'post',
            data: {
                AccountNumber: order.AccountNumber,
                SearchTerm: order.SearchTerm,
                Invoices: invoices,
                CompanyName: company.Key,
                PaymentTotal: total
            }
        })
            .then(jsonFromRequestEndpoint => {
                RealexHpp.setHppUrl(jsonFromRequestEndpoint.data.GPUrl);
                RealexHpp.embedded.init("checkoutButton", "checkoutIFrame", `./Payment/CheckoutCompleted?companyName=${company.Key}&orderNumber=${jsonFromRequestEndpoint.data.OrderNumber}`, JSON.parse(jsonFromRequestEndpoint.data.HppJson));
                if (window.addEventListener) {
                    window.addEventListener('message', RealexHpp.receiveMessage, false);
                } else {
                    window.attachEvent('message', RealexHpp.receiveMessage);
                }
            })
            .catch(e => {
                console.log(e);
                window.location.href = `${company.Key}/error`;
            });
    }, []);

    let handleSubmit = (event) => {
        setHideCheckoutSection(false);
    }

    return (
        <>
            { error ?
                <div className="alert alert-warning">
                    {t(error)}
                    <span hidden={errorCount < 3}>
                        &nbsp;<Trans t={t}>Please refer to the <Link to={'/' + company.Key + '/faqs'}>FAQ page</Link></Trans>
                    </span>
                </div>
                :
                <Button
                    id="checkoutButton"
                    className="btn-primary btn-lg btn-action float-left extra"
                    onClick={ handleSubmit  }
                        >{t('Confirm')}</Button>
            }
        </>
    );
}

function OrderCheckout({ hideCheckoutSection }) {
    hideCheckoutSection = false;
}