import React from 'react';
import { useParams, useLocation } from 'react-router';
import qs from "qs";
import { useFetch } from "./useFetch";
import { Button } from "./Button.js";
import { Currency } from './Payment.js'
import { CompanyContext } from '../CompanyContext';

export function Confirmation() {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { company, t } = React.useContext(CompanyContext);
    let { doFetch, isLoading } = useFetch();
    let location = useLocation();
    let { orderid } = useParams();

    const token = qs.parse(location.search.substr(1)).token;

    let [order, setOrder] = React.useState({});
    let [email, setEmail] = React.useState('');
    let [emailConfirm, setEmailConfirm] = React.useState('');
    let [dirty, setDirty] = React.useState({ email: false, emailConfirm: false });
    let [emailDelivered, setEmailDelivered] = React.useState(false);

    React.useEffect(() => {
        if (company?.Key) {
            doFetch({
                url: `/Payment/Get/${company.Key}`,
                params: {
                    OrderNumber: orderid
                }
            })
                .then(json => {
                    setOrder(json.data);
                });
        }
    }, [company]);

    let handleBlur = (event) => {
        const target = event.target;
        const name = target.name;
        setEmailDelivered(false);

        if (target.value && target.value.length > 0) {
            var dirtyState = { ...dirty };
            dirtyState[name] = true;
            setDirty(dirtyState);
        }
    }

    let handleSubmit = (event) => {
        doFetch({
            url: `/Payment/Email/${company.Name}`,
            method: 'post',
            data: {
                OrderNumber: orderid,
                Ticket: token,
                Email: email,
                Company: company.Name
            }
        })
            .then(res => {
                if (res.data.Ok) {
                    setEmail('');
                    setEmailConfirm('');
                    setDirty({ email: false, emailConfirm: false });
                    setEmailDelivered(true);
                }
            });
    }

    const validEmail = !dirty.email || emailRegex.test(email.toLowerCase());
    const matchEmail = (!dirty.email || !dirty.emailConfirm) || email === emailConfirm;
    const validForm = email.length > 0 && emailConfirm.length > 0 && validEmail && matchEmail;

    return (company &&
        <div className="container">
            <h1>{t('Order Confirmation')}</h1>
            <p className="d-print-none">{t('Step 4 of 4')}</p>
            <hr />

            <dl className="row">
                <dt className="col-sm-4">{t('Account Number')}</dt>
                <dd className="col-sm-8">{order.AccountNumber}</dd>

                <dt className="col-sm-4">{t('Invoice Numbers')}</dt>
                <dd className="col-sm-8">{order.InvoiceNumbers}</dd>

                <dt className="col-sm-4">{t('Health Organization')}</dt>
                <dd className="col-sm-8">{t(company.FullName)}</dd>

                <dt className="col-sm-4">{t('Health Organization Revenue Services Address')}</dt>
                <dd className="col-sm-8">{company.Address}</dd>

                <dt className="col-sm-4">{t('Total Payment')}</dt>
                <dd className="col-sm-8"><Currency value={order.PaymentTotal} /></dd>

                <dt className="col-sm-4">{t('Payment Method')}</dt>
                <dd className="col-sm-8">{t(order.PaymentMethod)}</dd>

                <dt className="col-sm-4">{t('Order ID')}</dt>
                <dd className="col-sm-8">{order.OrderNumber}</dd>

                <dt className="col-sm-4">{t('Confirmation Number')}</dt>
                <dd className="col-sm-8">{order.ConfirmationNumber}</dd>
            </dl>

            <Button onClick={() => window.print()} className="btn-primary btn-action btn-lg d-print-none">{t('Print Confirmation')}</Button>
            <hr />

            <form className="d-print-none" onSubmit={(e) => handleSubmit(e)}>
                <h3>{t('Email a Copy')}</h3>
                <p>{t('Please enter email twice to confirm')}</p>

                <div className="row">
                    <div className="col-md-8 form-group">
                    <input type="email"
                            name="email" 
                            value={email}
                            className={(validEmail ? '' : 'is-invalid') + ' form-control'}
                            required
                            placeholder="john.smith67@gmail.com"
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={(e) => handleBlur(e)} />
                        <div className="text-danger small invalid-feedback" hidden={validEmail}>
                            {t('Please enter a valid email address')}
                        </div>
                    </div>

                    <div className="col-md-8 form-group">
                    <input type="email"
                            name="emailConfirm"
                            value={emailConfirm}
                            className={(matchEmail ? '' : 'is-invalid') + ' form-control'}
                            required
                            placeholder="john.smith67@gmail.com"
                            onChange={(e) => setEmailConfirm(e.target.value)}
                            onBlur={(e) => handleBlur(e)} />
                        <div className="text-danger small invalid-feedback" hidden={matchEmail}>
                            {t('Emails do not match. Please double check the email address.')}
                        </div>
                    </div>
                </div>

                <p>
                    <Button
                        className="btn-primary"
                        disabled={!validForm}
                        loading={isLoading}
                        onClick={(e) => handleSubmit(e)}>{t('Email')}</Button>
                    <span className="text-success small px-2" hidden={!emailDelivered}>{t('A copy of this confirmation has been sent')}</span>
                </p>

                <div className="card card-body bg-light border-light">
                    {t('Your payment is being processed. Please check your credit card statement, or call Revenue Services (604)-297-8512 in order to confirm the payment has been successful.')}
                </div>
            </form>
        </div>
    );
}
