import React from 'react';
import axios from 'axios';
import { CompanyContext } from '../CompanyContext';

const useFetch = () => {
    const { setError } = React.useContext(CompanyContext);

    const [data, setData] = React.useState({});
    const [opts, setOpts] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [promise, setPromise] = React.useState({});

    const doFetch = (opts) => {
        setOpts(opts);

        return new Promise((resolve, reject) => {
            setPromise({ resolve, reject });
        });
    }

    React.useEffect(() => {
        const fetchData = async (resolve, reject) => {
            setError(null);
            setIsLoading(true);

            try {
                const result = await axios(opts)
                    .catch(error => {
                        setPromise({});
                        setIsLoading(false);

                        const response = error.response;

                        if (!response) {
                            reject(error);
                            return;
                        }

                        setError({
                            Message: 'This site is currently experiencing technical difficulty',
                            Dismissable: true,
                            Code: '0x02'
                        });
                    });

                resolve(result);
                setData(result.data);
            } catch (error) {
                reject(error);
                setError({
                    Message: 'This site is currently experiencing technical difficulty',
                    Dismissable: true,
                    Code: '0x03'
                });
            }

            setPromise({});
            setIsLoading(false);
        };

        if (promise.resolve && promise.reject) {
            fetchData(promise.resolve, promise.reject);
        }
    }, [promise]);

    return { data, isLoading, doFetch };
}

export { useFetch };
