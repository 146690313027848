import React from 'react';
import { Element, scroller } from "react-scroll";
import { CompanyContext } from '../CompanyContext';

import "./Alert.css";

export function Alert() {
    const { error, t } = React.useContext(CompanyContext);
    let [hidden, setHidden] = React.useState(false);

    React.useEffect(() => {
        if (!error || !error.Message) return;

        console.error(error);

        if (error?.Message) {
            setHidden(false);

            scroller.scrollTo('site-alert', {
                duration: 400,
                delay: 0,
                smooth: "easeInOutQuart"
            });
        }
    }, [error]);

    return (
        <>
            {error?.Message && hidden === false &&
                <div id="site-alert" className="site-alert">
                    <div className="backdrop" />
                    <div className="alert alert-danger">
                        {t(error.Message)}
                        {error.Code && <span> ({error.Code})</span>}
                        {error.Dismissable &&
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={(e) => setHidden(true)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    </div>
                </div>
            }
        </>
    );
}