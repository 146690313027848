import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from "./Modal.js";
import { CompanyContext } from '../CompanyContext';

import './HealthOrganization.css';

export function HealthOrganization() {
    const { config, t} = React.useContext(CompanyContext);
    let history = useHistory();

    const [companies, setCompanies] = React.useState([]);
    const [organizations, setOrganizations] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [showCategory, setShowCategory] = React.useState('');

    React.useEffect(() => {
        setCompanies(config.Companies);
    }, [config]);

    React.useEffect(() => {
        if (companies) {
            let _organizations = Object.keys(companies)
                // Flatten object
                .reduce((acc, key) => {
                    let val = companies[key];
                    val['Key'] = key;
                    return acc.concat(val);
                }, [])
                // Group by Name
                .reduce((acc, val) => {
                    (acc[val['Name']] = acc[val['Name']] || []).push(val);
                    return acc;
                }, {});

            let _locations = Object.keys(companies)
                .map(c => companies[c].Locations ? companies[c].Locations.map(loc => [c, loc]) : null)
                .reduce((acc, val) => acc.concat(val), []) // same as .flat()
                .filter(p => p != null)
                .sort((a, b) => { // Sorts array alphabetically
                    var nameA = a[1].toUpperCase(); // ignore upper and lowercase
                    var nameB = b[1].toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });

            setOrganizations(_organizations);
            setLocations(_locations);
        }
    }, [companies]);

    let handleSelectChange = (event) => {
        const target = event.target;

        if (target.value && companies[target.value]) {
            history.push('/' + target.value);            
        }
    }

    return (
        <div>
            <div className="container mt-3">
                <p className="lead text-center py-5">{t('Please select the health organization on your invoice')}</p>
                <div className="row company-row">
                    {Object.keys(organizations).map(name => {
                        let org = organizations[name];
                        let company = org[0]; // Grouped company uses the first logo

                        return <Link to={'/' + company.Key} key={name} className="col-sm text-center mb-4"
                            onClick={(event) => {
                                if (org.length > 1) {
                                    setShowCategory(name);
                                    event.preventDefault();
                                }
                            }}>
                            <img src={company.Logo} className="company-logo" alt={t(company.FullName)} title={t(company.FullName)} />
                            <br />
                            <span className="btn btn-sm btn-primary my-4">{t('Select')}</span>
                        </Link>
                    })}
                </div>

                <p className="lead text-center mt-3 mb-5">{t('Don\'t know? Please pick your hospital or treatment facility')}</p>

                <div className="row">
                    <div className="col-md-8 offset-md-2 text-center">
                        <select className="form-control form-control-lg" onChange={handleSelectChange} defaultValue="">
                            <option disabled value="">{t('Please select a care facility')}</option>

                            {locations.map(arr =>
                                <option key={arr[1]} value={arr[0]}>{t(arr[1])}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>

            {showCategory && organizations[showCategory] &&
                <Modal
                    onClose={() => setShowCategory('')}
                    onButtonClick={() => setShowCategory('')}
                    buttonLabel={t('Close')}
                    showFooter={true}>
                    <h6 className="text-center lead my-3">{t('Please select bill type for payment')}</h6>
                    <div className="row">
                        {organizations[showCategory].map(company =>
                            <div className="col-xs-12 col-sm-6 d-flex align-items-stretch" key={company.Category}>
                                <Link to={'/' + company.Key} key={company.Category} className="card p-4 mx-auto bg-light border-0 text-center d-block">
                                    <img src={company.Logo} className="company-logo mb-2" alt={t(company.FullName)} title={t(company.FullName)} />
                                    <span className="h5 text-dark d-block">{t(company.Category)}</span>
                                    <span className="btn btn-sm btn-primary mt-3">{t('Select')}</span>
                                </Link>
                            </div>
                        )}
                    </div>
                </Modal>
            }
        </div>
    );
}
