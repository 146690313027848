import React, { Suspense } from 'react';
import { Route } from 'react-router';
import CompanyContext from './CompanyContext';

import { Layout } from './components/Layout';
import { Payment } from './components/Payment';
import { HealthOrganization } from './components/HealthOrganization';
import { Faqs } from './components/Faqs';
import { Cancel } from './components/Cancel';
import { Confirmation } from './components/Confirmation';
import { Decline } from './components/Decline';
import { Error } from './components/Error';

import './custom.css'

export default function App() {
    return (
        <Suspense fallback="Loading...">
            <CompanyContext>
                <Layout>
                    <Route exact path='/' component={HealthOrganization} />
                    <Route path='/:org/declined' component={Decline} />
                    <Route path='/:org/error' component={Error} />
                    <Route path='/:org/cancel' component={Cancel} />
                    <Route path='/:org/faqs' component={Faqs} />
                    <Route path='/:org/:orderid(VPP\-\d+)' component={Confirmation} />
                    <Route path='/:org/:section?' component={Payment} />
                </Layout>
            </CompanyContext>
        </Suspense>
    );
}
