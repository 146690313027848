import React from 'react';
import { CompanyContext } from '../CompanyContext';

export function Faqs() {
    const { company, t } = React.useContext(CompanyContext);
    const [html, setHtml] = React.useState('');

    React.useEffect(() => {
        if (company) {
            fetch(`faqs/${company.Key}.html`)
                .then(res => {
                    return res.text();
                })
                .then(text => {
                    setHtml(text);
                });
        }
    }, [company]);

    const createMarkup = () => {
        return { __html: html };
    };

    return (
        <div>
            <div className="container content-block">
                <h1>{t('Frequently Asked Questions (FAQs)')}</h1>
                <hr />
                <div dangerouslySetInnerHTML={createMarkup()}></div>
            </div>
        </div>
    );
}
